.marquee__text {
  overflow: clip;
}

.marquee__text__track {
  display: flex;
  padding-left: 4rem;
  gap: 4rem;
  width: max-content;
  animation: marquee-move-text var(--speed, 80s) linear infinite
    var(--direction, forwards);
}

@keyframes marquee-move-text {
  to {
    transform: translateX(-50%);
  }
}
